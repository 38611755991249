<template>

  <div style="
      background-color: #f6f6f6;
      height: 100%;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bott);
    ">
    <!-- 申请退款 -->
    <van-nav-bar v-if="type==2" title="申请退款" left-arrow @click-left="back" fixed placeholder>
    </van-nav-bar>
    <!-- 申请售后 -->
    <van-nav-bar v-if="type==1" title="申请售后" left-arrow @click-left="$router.back()" fixed placeholder>
    </van-nav-bar>

    <div style="height: calc(100vh - 102px); overflow: scroll">
      <!-- 商品 -->
      <div class="box" style="margin-top: 10px">
        <!-- 退款致电商家 -->
        <div v-if="type==2" class="box-title" @click="afterSale(currentData.phone,1)">
          <div>{{currentData.orderName}}</div>
          <div style="display: flex;">
            <van-icon name="phone" />
            <a id="tel" href="javascript:void(0)"
              style="display: flex;flex-direction: column;align-items: center;font-size: 12px">致电商家</a>
          </div>
        </div>
        <!-- 致电售后 -->
        <div v-if="type==1" class="box-title">
          <div>{{currentData.orderName}}</div>
          <div @click="afterSale('400-8721-8321',2)" style="display: flex;">
            <van-icon name="phone" />
            <a id="tel" href="javascript:void(0)"
              style="display: flex;flex-direction: column;align-items: center;font-size: 12px">平台售后</a>
          </div>
        </div>
        <!-- 退款商品 -->
        <div v-if="type==2" class="goods">
          <van-checkbox-group v-model="result" ref="checkboxGroup" @change="groupChange">
            <!-- 售后商品 -->
            <div v-for="(item, index) in currentData.takeoutOrderGoods" :key="index" class="goods-item">
              <van-checkbox :name="index" style="width: 40px"></van-checkbox>
              <van-image width="60" height="60" :src="item.goodsUrl"
                style="border-radius: 10px; overflow: hidden; flex-shrink: 0" />
              <div style="flex: 1; margin-left: 10px">
                <div class="item-title">
                  <div>{{item.goodsName}}</div>
                  <div>￥{{item.goodsPrice}}</div>
                </div>
                <div>
                  <div style="display: flex;width: 200px;flex-wrap: wrap;">
                    <van-tag style="margin-right:8px" color="#eee" text-color="#999"
                      v-for="(j,k) in item.goodsStandardNames" :key="k">{{j}}
                    </van-tag>
                  </div>
                </div>
                <div>x{{item.goodsNum}}</div>
              </div>
            </div>
          </van-checkbox-group>
        </div>
        <div v-if="type==2" class="box-content">
          <!-- <div class="content-inner" style="color: #333">
            <div>打包费</div>
            <div>￥{{currentData.pricePackage}}</div>
          </div> -->
          <div class="content-inner" style="color: #999;margin-left: 40px;font-size: 12px;">
            <div>配送费</div>
            <div>￥{{currentData.priceTransportUser}}</div>
          </div>
          <div style="margin: 15px 0;height: 1px;border-top: 1px solid #000;opacity: .1;"></div>
          <!-- <div class="content-inner" style="color: #333">
            <div>优惠券</div>
            <div>￥{{currentData.priceCouponReduce?currentData.priceCouponReduce:0}}</div>
          </div> -->
          <div class="content-inner" style="color: #333">
            <div></div>
            <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
              <div>
                <van-checkbox @click="checkAll" v-model="checkedAll">全选</van-checkbox>
              </div>
              <div>
                <span>退款金额：</span>
                <span style="font-size: 18px;color: #a80000">￥{{money}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 售后商品 -->
        <div v-if="type==1" class="box">
          <div class="box-title">商品</div>
          <div class="goods">
            <div v-for="(item, index) in currentData.takeoutOrderGoods" :key="index" class="goods-item">
              <van-image width="60" height="60" :src="item.goodsUrl"
                style="border-radius: 10px; overflow: hidden; flex-shrink: 0" />
              <div style="flex: 1; margin-left: 10px">
                <div class="item-title">
                  <div>{{item.goodsName}}</div>
                  <div>￥{{item.goodsPrice}}</div>
                </div>
                <div>
                  <van-tag color="#eee" text-color="#999" v-for="(j,k) in item.goodsStandardNames" :key="k">{{j}}
                  </van-tag>
                </div>
                <div>x{{item.goodsNum}}</div>
              </div>
            </div>
          </div>
          <div class="box-content">
            <div class="content-inner" style="color: #333">
              <div>打包费</div>
              <div>￥{{currentData.pricePackage}}</div>
            </div>
            <div class="content-inner" style="color: #333">
              <div>配送费</div>
              <div>￥{{currentData.priceTransportUser}}</div>
            </div>
            <div class="content-inner" style="color: #333">
              <div>优惠券</div>
              <div>-￥{{currentData.priceCouponReduce?currentData.priceCouponReduce:0}}</div>
            </div>
            <div class="content-inner" style="color: #333">
              <div></div>
              <div>
                <span>已优惠￥{{priceCouponReduce}} 合计：</span>
                <span style="color: #a80000">
                  <span></span>
                  <span style="font-size: 18px">￥{{currentData.payFee}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 配送信息 -->
      <!-- <div v-if="type==1" class="box">
        <div class="box-title">配送信息</div>
        <div class="box-content">
          <div class="content-inner">
            <div>配送地址</div>
            <div>{{currentData.acceptAddress}}</div>
          </div>
          <div class="content-inner">
            <div></div>
            <div>
              <span>{{currentData.acceptName}}</span>&nbsp;&nbsp;
              <span>{{currentData.acceptPhone}}</span>
            </div>
          </div>
          <div class="content-inner">
            <div>配送服务</div>
            <div>Win生活快送配送</div>
          </div>
        </div>
      </div> -->

      <!-- 订单信息 -->
      <!-- <div v-if="type==1" class="box">
        <div class="box-title">订单信息</div>
        <div class="box-content">
          <div class="content-inner">
            <div>订单号码</div>
            <div>{{currentData.id}}</div>
          </div>
          <div class="content-inner">
            <div>下单时间</div>
            <div>{{currentData.createTime}}</div>
          </div>
          <div class="content-inner">
            <div>支付方式</div>
            <div>{{currentData.payType==3?'支付宝':'微信'}}</div>
          </div>
        </div>
      </div> -->

      <!-- 投诉对象 -->
      <div v-if="type==1" class="box" style="padding: 0">
        <van-cell title="投诉对象" :label="direction?'':'请选择投诉对象'" is-link :value="direction" @click="show = true" />
      </div>

      <!-- 退款原因 -->
      <div class="box">
        <div class="box-title">退款原因</div>
        <!-- 文本域 -->
        <van-field v-model="message" rows="2" autosize type="textarea" maxlength="100"
          placeholder="补充详细退款原因，有利于商家更快的帮您处理" show-word-limit
          style="background-color: #eee; border-radius: 10px; margin: 10px 0" />

        <!-- 图片上传 -->
        <van-uploader v-model="images" :max-size="5000 * 1024" @oversize="onOversize" :max-count="5"
          :after-read="afterRead" :before-delete="beforeDelete" />
      </div>
    </div>

    <!-- 退款至您的支付账户 -->
    <div class="bottom-btn">
      <div class="bottom-inner">
        <div class="inner-left">
          <div class="left-inner">
            <div>
              <span style="font-size: 12px">￥</span>
              <span style="font-size: 14px">{{money}}</span>
              &nbsp;
              <span style="color: #999">退款金额</span>
            </div>
            <div>退款至您的支付账户</div>
          </div>
        </div>
        <div class="inner-right linear-btn-color" @click="confirm">提交</div>
      </div>
    </div>

    <!-- 投诉对象 -->
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" cancel-text="取消" close-on-click-action />
  </div>
</template>

<script>
  import { complaint, twominutescancelorder } from '/src/api/takeOut/cancelReason/index.js'
  import { upload } from "@/api/upload";
  import { Toast } from "vant";
  // import qs from 'qs'
  export default {
    name: "afterSale",
    components: {},
    data() {
      return {
        checkedAll: false,// 全选
        isShow: '',
        currentData: {
          userRemark: '',//下单备注
          acceptAddress: '',//收获地址
          acceptPhone: '',//下单人号码
          pricePackage: '0',//打包费
          priceTransportUser: '',//配送费
          priceCouponReduce: '',//优惠券减少费用
          payFee: '',//支付费用（合计）
          payFeeReduce: '',//优惠费用
          acceptName: '',//下单人姓名
          id: '',//订单id
          createTime: '',//下单时间
          payType: '',//支付方式3支付宝 4微信
          couponReduceId: '',//优惠券id
          takeoutOrderGoods: {// 订单商品
            id: '',// 订单商品id
            createTime: '',// 创建时间
            orderId: '',// 所属订单id
            goodsId: '',// 订单商品id
            goodsName: '',// 商品名
            goodsNum: '',// 订单商品数量
            goodsPrice: '',// 商品购买时单价
            packingPrice: '',// 打包费
            goodsTotalPrice: '',// 商品价格（加上规格之后的*数量的价格）
            goodsStandardIds: '',// 商品规格 规格id:选项id_规格id:选项id
            goodsStandardNames: '',// 商品规格名字 用于显示 口味:微辣_分量:1人份
            goodsUrl: [],// 商品图片
          }
        },//订单数据
        type: '',// 1退款 2售后
        arr: [{}, {}],
        result: [],
        checked: false,
        show: false,
        direction: "",
        actions: [{ name: "骑手" }, { name: "商家" }],
        message: "",
        images: [],// 上传文件
        ComplaintUrl: [],//图片地址
        dialog: true,// 超时弹窗
      };
    },
    created() {
      this.type = this.$route.params.type
      this.currentData = this.$route.params.obj
      this.isShow = this.$route.params.isShow
      console.log(this.type)
    },
    mounted() {

    },
    computed: {
      // 退款/售后金额
      money() {
        let result = 0
        for (let i = 0; i < this.result.length; i++) {
          result += this.currentData.takeoutOrderGoods[this.result[i]].goodsPrice * this.currentData.takeoutOrderGoods[this.result[i]].goodsNum
        }
        if (this.result.length == this.currentData.takeoutOrderGoods.length) {
          return (Number(result) + Number(this.currentData.pricePackage) + Number(this.currentData.priceTransportUser)).toFixed(2)
        } else {
          console.log(result)
          return result.toFixed(2)
        }
      },
      // 以优惠价格
      priceCouponReduce() {
        let num = 0
        num += Number(this.currentData.priceCouponReduce) + Number(this.currentData.priceFullReduce)
        if (num) {
          return num
        } else {
          return 0
        }
      }

    },
    beforeDestroy() {

    },
    methods: {
      // 多选
      groupChange(val) {
        if (val.length == this.arr.length) {
          this.checked = true;
        } else {
          this.checked = false;
        }
        if (this.result.length == this.currentData.takeoutOrderGoods.length) {
          this.checkedAll = true
        } else {
          this.checkedAll = false
        }
      },
      // 全选
      checkAll() {
        if (this.checkedAll) {
          this.$refs.checkboxGroup.toggleAll(true);
        } else {
          this.$refs.checkboxGroup.toggleAll(false);
        }
      },
      // 投诉对象选择
      onSelect(item) {
        this.direction = item.name;
      },
      // 文件上传
      afterRead(file) {
        console.log(file);
        file.status = "uploading";
        file.message = "上传中";
        let formData = new window.FormData();
        formData.append("file", file.file);
        formData.append("fileType", "image/png");
        formData.append("dir", "material");
        upload(formData)
          .then((res) => {
            console.log(res);
            file.picUrl = res.data.data;
            file.status = "";
            file.message = "";
            this.ComplaintUrl.push(file.picUrl)

            // console.log(this.ComplaintUrl)
          })
          .catch(() => {
            file.status = "failed";
            file.message = "上传失败";
          });
      },
      // 提示文件过大
      onOversize(file) {
        console.log(file);
        Toast('文件大小不能超过 5M');
      },
      // 删除图片
      beforeDelete(file) {
        this.images = this.images.filter((x) => {
          return x.picUrl != file.picUrl;
        });
      },
      // 提交
      confirm() {
        // 退款
        if (this.type == 2) {
          if (this.isShow) {
            this.$dialog
              .confirm({
                title: "提示",
                message: this.dialog ? "您的订单已经在配送中,真的不再等等了吗？" : "超时退款可能将会被商家驳回是否继续退款",
                confirmButtonText: "再等等",
                confirmButtonColor: "#576B95",
                cancelButtonText: "仍然退款",
              })
              .then(() => {
                console.log('取消')
              })
              .catch(() => {
                // 退款
                this.sunmit()
              });
          } else {
            // 退款
            this.sunmit()
          }
        }
        // 售后
        if (this.type == 1) {
          // 是否选择商品
          // if (this.result.length == 0) {
          //   return Toast("请选择退款商品");
          // }
          // 是否选中投诉对象
          if (!this.direction) {
            this.show = true
            return
          }
          this.after()
        }
      },
      // 退款
      sunmit() {
        if (this.result.length == 0) {
          Toast('请选择退款商品');
          return
        }
        let param = {
          id: this.currentData.id,// 订单id
          cancelReason: this.currentConten,// 取消原因
          delFlag: 0,//0-第一次取消 1-确认取消
        }
        twominutescancelorder(param).then(res => {
          console.log(res)
          console.log('退款')
          let msg = ''
          if (res.data.code == 0) {
            switch (String(res.data.data.flag)) {
              case '0':
                msg = '退款成功';
                break;
              case '1':
                msg = '提交退款申请，待商家确认?';
                break;
              case '2':
                msg = '您的订单已经在配送中,真的不再等等了吗?';
                break;
              case '3':
                msg = "超时退款可能将会被商家驳回是否继续退款?";
                break;
            }
            console.log(msg)
            if (Number(res.data.data.flag) != 0) {
              this.$dialog
                .confirm({
                  title: "提示",
                  message: msg,
                  confirmButtonText: "取消",
                  confirmButtonColor: "#576B95",
                  cancelButtonText: "仍然退款",
                })
                .then(() => {
                  console.log('取消')
                })
                .catch(() => {
                  // 确认退款
                  let param = {
                    id: this.currentData.id,// 订单id
                    cancelReason: this.currentConten,// 取消原因
                    delFlag: 1,//0-第一次取消 1-确认取消
                  }
                  twominutescancelorder(param).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                      Toast('提交成功');
                      this.$router.push({ path: '/takeOutOrderDetail', query: { id: this.orderId } })
                    } else {
                      if (res.data.msg) {
                        Toast(res.data.msg);
                      }
                    }
                  })
                });
            } else {
              let param = {
                id: this.currentData.id,// 订单id
                cancelReason: this.currentConten,// 取消原因
                delFlag: 1,//0-第一次取消 1-确认取消
              }
              twominutescancelorder(param).then(res => {
                console.log(res)
                if (res.data.code == 0) {
                  Toast('提交成功');
                  this.$router.push({ path: '/takeOutOrderDetail', query: { id: this.orderId } })
                } else {
                  if (res.data.msg) {
                    Toast(res.data.msg);
                  }
                }
              })
            }
            // 清除选中
            this.result = []
          } else {
            Toast(res.data.msg);
          }
        })
      },
      // 售后
      after() {
        console.log('售后')
        this.ComplaintUrl = this.ComplaintUrl.join(',')
        console.log(this.money)
        let param = {
          id: this.currentData.id,//订单id
          ComplaintReason: this.message,//投诉原因=>非必填
          ComplaintUrl: this.ComplaintUrl,//投诉截图=>非必填
          ComplaintTarget: this.direction == '骑手' ? 1 : 0,//投诉目标 0商家 1骑手
          complaintScope: this.checkedAll ? 0 : 1,//投诉商品的范围 0全部 1部分商品
          complaintPrice: this.money//退款费用
        }

        console.log(param)
        complaint(param).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            Toast("提交成功");
            // 清除选中
            // this.result = []
            this.message = ''
            this.$router.back()
          } else {
            if (res.data.msg) {
              Toast(res.data.msg);
            }
          }
        })
      },
      // 返回
      back() {
        this.$router.back()
        this.checkedAll = false
        this.result = []
        this.images = []
        this.ComplaintUrl = []
        this.message = []
        this.direction = ''
      },
      // 打电话
      afterSale(num, type) {
        let msg = ''
        if (type == 1) {
          msg = `联系商家电话：${num}`
        } else {
          msg = `平台售后电话：${num}`
        }
        this.$dialog
          .confirm({
            message: msg,
            confirmButtonColor: "#576B95",//按钮文字颜色
            confirmButtonText: '打电话',// 按钮文字
            showCancelButton: false,// 不显示取消按钮
            closeOnClickOverlay: true,// 点击遮罩关闭弹窗
          })
          .then(() => {
            document.getElementById('tel').href = `tel:${num}`
            document.getElementById('tel').click()
          })
      },
    },
  };
</script>

<style lang="less" scoped>
  .box {
    margin: 0 5px 10px;
    padding: 11px;
    background-color: #fff;
    border-radius: 10px;

    .box-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .box-content {
      .content-inner {
        display: flex;
        justify-content: space-between;
        color: #999;
        line-height: 2;
      }
    }
  }

  .goods {
    .goods-item {
      display: flex;
      padding: 10px 0;
      color: #999;

      .item-title {
        display: flex;
        justify-content: space-between;
        color: #333;
      }
    }
  }

  /deep/ .van-cell__title {
    display: flex;

    .van-cell__label {
      margin-left: 10px;
    }
  }

  .bottom-btn {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 10px;

    .bottom-inner {
      height: 36px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;

      .inner-left {
        flex: 7;
        background-color: #313131;
        display: flex;
        align-items: center;
        padding: 10px;

        .left-inner {
          line-height: 1;
          color: #fff;
          font-size: 10px;
        }
      }

      .inner-right {
        color: #fff;
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>